import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
// Import Contexts
import { ProgramConfigContext } from '../../contexts/ProgramConfigContext';
// Import Local Routes
import SelectTransfer from './routes/SelectTransfer';
import PersonalAccountTransfer from './routes/PersonalAccountTransfer';
import AccountToAccountTransfer from './routes/AccountToAccountTransfer';
import PersonToPersonTransfer from './routes/PersonToPersonTransfer';
import PersonToPersonAccounts from './routes/PersonToPersonAccounts';
import RecipientAccounts from './routes/RecipientAccounts';
import PersonToExternalTransfer from './routes/PersonToExternalTransfer';
import EtransferPush from './routes/EtransferPush';
import EtransferPull from './routes/EtransferPull';
import DirectsendPush from './routes/DirectsendPush';
import DirectsendPull from './routes/DirectsendPull';
// Import Local Components
import TransferRoute from './components/Route';

const Transfer = ({ match: { path } }) => {
  const configs = useContext(ProgramConfigContext);

  return (
    <Switch>
      <TransferRoute
        path={`${path}/my-account`}
        enabled={configs.allow_personal_bank_account_transfers}
        render={routeProps => <PersonalAccountTransfer {...routeProps} />}
      />
      <TransferRoute
        path={`${path}/card-to-card/accounts`}
        enabled={configs.allow_account_to_account_transfer}
        render={routeProps => <RecipientAccounts {...routeProps} />}
      />
      <TransferRoute
        path={`${path}/card-to-card`}
        enabled={
          configs.allow_account_to_account_transfer || configs.allow_child_cards
        }
        render={routeProps => (
          <AccountToAccountTransfer configs={configs} {...routeProps} />
        )}
      />
      <TransferRoute
        path={`${path}/person-to-person/accounts`}
        enabled={configs.allow_person_to_person_transfers}
        render={routeProps => <PersonToPersonAccounts {...routeProps} />}
      />
      <TransferRoute
        path={`${path}/person-to-person`}
        enabled={configs.allow_person_to_person_transfers}
        render={routeProps => <PersonToPersonTransfer {...routeProps} />}
      />
      <TransferRoute
        path={`${path}/person-to-external`}
        enabled={configs.allow_interac_pull_transfers}
        render={routeProps => <PersonToExternalTransfer {...routeProps} />}
      />
      <TransferRoute
        path={`${path}/etransfer-push`}
        enabled={configs.allow_external_transfers_v2 && configs.allow_etransfer_push_v2}
        render={routeProps => <EtransferPush configs={configs} {...routeProps} />}
      />
      <TransferRoute
        path={`${path}/etransfer-pull`}
        enabled={configs.allow_external_transfers_v2}
        render={routeProps => <EtransferPull {...routeProps} />}
      />
      <TransferRoute
        path={`${path}/directsend-push`}
        enabled={configs.allow_external_transfers_v2}
        render={routeProps => <DirectsendPush {...routeProps} />}
      />
      <TransferRoute
        path={`${path}/directsend-pull`}
        enabled={configs.allow_external_transfers_v2}
        render={routeProps => <DirectsendPull {...routeProps} />}
      />
      <Route
        exact
        path={`${path}`}
        render={routeProps => (
          <SelectTransfer configs={configs} {...routeProps} />
        )}
      />
    </Switch>
  );
};

Transfer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default Transfer;
