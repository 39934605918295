import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Input, ActionBar } from 'shared-components';
import { numberDollarsToCents } from 'shared-components/src/utils/currency';
import InputGroup from '../../../../../layouts/InputGroup';
import InputRow from '../../../../../layouts/InputRow';
import Form from '../../../../../layouts/Form';
import SubmitButton from '../../../../../components/SubmitButton';
import EditableDropdown from '../../../components/EditableDropDown/EditableDropDown';

const ExternalTransferEtransferPushForm = ({
  disableSubmit,
  externalAccounts,
  setPushParams,
  setShowConfirmModal,
  page,
  deleteExternalAccount,
  editExternalAccount,
  setAlert,
}) => {
  const intl = useIntl();
  const securityQuestionRef = useRef(null);
  const securityAnswerRef = useRef(null);

  const [amountInput, setAmountInput] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    setAlert(false);

    if (!(selectedOption && selectedOption.id)) {
      setAlert({
        type: 'danger',
        message: 'Please select a contact to send funds to.',
        messageFor: 'selection-error',
        component: 'etransfer-push',
      });
      return;
    }

    setPushParams({
      amount: numberDollarsToCents(amountInput),
      transfer_type: 'direct_send_etransfer_push',
      external_account_id: parseInt(selectedOption.id, 10),
      security_question: securityQuestionRef.current.value,
      security_question_answer: securityAnswerRef.current.value,
    });
    setShowConfirmModal(true);
  };

  const handleSelect = option => {
    setSelectedOption(option);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputRow>
        <InputGroup
          label={intl.messages[`${page}-form-select-external-account-label`]}
          labelFor="externalAccounts"
          page={page}
          required
        >
          <EditableDropdown
            options={externalAccounts}
            selectedOption={selectedOption}
            onSelect={handleSelect}
            onEdit={editExternalAccount}
            onDelete={deleteExternalAccount}
            page={page}
          />
        </InputGroup>
        <InputGroup
          required
          label={intl.messages[`${page}-amount-label`]}
          labelFor="amount"
          page={page}
        >
          <Input
            name="amount"
            pattern="^\d+(\.\d{1,2})?$"
            title="Please enter a valid amount (minimum 0.01, up to 2 decimal places)"
            placeholder="00.00"
            type="number"
            step="0.01"
            min="0.01"
            value={amountInput}
            onChange={e => setAmountInput(e.target.value)}
            required
          />
        </InputGroup>
        <InputGroup
          required
          label={intl.messages[`${page}security-question-label`]}
          labelFor="security-question"
          page={page}
        >
          <Input
            id="security-question-input"
            type="text"
            ref={securityQuestionRef}
            title="Security question must be 3-40 characters long and contain only letters (A-Z, a-z), spaces, and the '?' character."
            pattern="^[A-Za-z? ]{3,40}$"
            required
          />
        </InputGroup>
        <InputGroup
          required
          label={intl.messages[`${page}security-answer-label`]}
          labelFor="security-answer"
          page={page}
        >
          <Input
            id="security-answer-input"
            type="text"
            ref={securityAnswerRef}
            title="Security answer must be 3-25 characters long and contain only letters and numbers."
            pattern="^[0-9A-Za-z]{3,25}$"
            required
          />
        </InputGroup>
      </InputRow>
      <ActionBar>
        <SubmitButton disabled={disableSubmit} />
      </ActionBar>
    </Form>
  );
};

ExternalTransferEtransferPushForm.propTypes = {
  externalAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      vanity_identifier: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  setPushParams: PropTypes.func.isRequired,
  setShowConfirmModal: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  deleteExternalAccount: PropTypes.func.isRequired,
  editExternalAccount: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default ExternalTransferEtransferPushForm;
